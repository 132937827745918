<template>
  <div class="flex justify-center items-center h-full">
    <div v-if="params.data.active" class="state active" />
    <div v-else class="state inactive" />
  </div>
</template>

<script>
export default {
  name: 'CellRendererPlantStatus',
  methods: {
    editRecord() {
      this.$router
        .push(
          '/comercial/negociaciones/editar-negociacion/' + this.params.data.id
        )
        .catch(() => {});

      /*
              Below line will be for actual product
              Currently it's commented due to demo purpose - Above url is for demo purpose
              this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
            */
    },
    configRecord() {
      // this.params.context.componentParent.changeStatus({
      //   status: this.params.data.status,
      //   negociacion: this.params.data,
      //   observacion: `Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}, Value: ${this.params.data.status}`,
      // });
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar eliminación`,
        text: `¿Estás seguro de borrar "${this.params.data.nombre}"?`,
        accept: this.deleteRecord,
        acceptText: 'Eliminar',
        cancelText: 'Cancelar',
      });
    },
    deleteRecord() {
      this.showDeleteSuccess();
    },
    showDeleteSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Planta eliminada',
        text: 'La planta fue correctamente eliminada',
      });
    },
  },
};
</script>
<style scoped>
.active {
  background: rgb(var(--vs-success)) !important;
}

.state {
  align-items: center;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.inactive {
  background: rgb(var(--vs-danger)) !important;
}
</style>

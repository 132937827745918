<template>
  <vx-card class="tabla-adminPlantas">
    <!-- TABLE ACTION ROW -->
    <div class="vx-row hidden md:flex">
      <div class="vx-col mb-3">
        <vs-button
          color="success"
          icon="power_settings_new"
          @click="changeStatusPlantDialog(true)"
          >Activar
        </vs-button>
      </div>
      <div class="vx-col mb-3">
        <vs-button
          color="danger"
          icon="power_settings_new"
          @click="changeStatusPlantDialog(false)"
          >Desactivar
        </vs-button>
      </div>
      <div class="vx-col mb-3">
        <vs-button
          class="bg-link-water text-slate-gray font-bold"
          @click="sendNotificationDialog"
          >Enviar notificación
        </vs-button>
      </div>
      <div class="vx-col mb-3">
        <vs-button
          class="bg-link-water text-slate-gray font-bold"
          @click="resetDelegationsDialog"
          >Eliminar delegación
        </vs-button>
      </div>
      <div class="vx-col mb-3">
        <vs-button
          class="bg-link-water text-slate-gray font-bold"
          @click="fusionDialog"
          >Fusionar
        </vs-button>
      </div>

      <div class="vx-col mb-3">
        <vs-button
          class="bg-link-water text-slate-gray font-bold"
          @click="syncDelegations"
          >Sincronizar
        </vs-button>
      </div>
    </div>
    <!-- ./TABLE ACTION ROW -->
    <div class="flex flex-wrap sm:flex-no-wrap justify-between items-center">
      <vs-select
        v-model="searchPanel.franchise_id"
        class="w-full mr-3 mb-3"
        label="Franquicia"
        placeholder="Seleccione una franquicia"
      >
        <vs-select-item :value="null" text="Seleccione una franquicia" />
        <vs-select-item
          v-for="(item, i) in franchises"
          :key="i"
          :value="item.id"
          :text="item.name"
        />
      </vs-select>
      <div class="flex flex-wrap sm:flex-no-wrap sm:mt-5">
        <vs-button @click="getPlantSearch" class="mr-3 mb-3"
          >Consultar
        </vs-button>
        <vs-button
          @click="exportAllPlants"
          type="border"
          class="hidden md:block mb-3"
          >Exportar
        </vs-button>
        <!-- ACTION - DROPDOWN -->
        <vs-dropdown vs-trigger-click class="cursor-pointer md:hidden">
          <div
            class="p-3 mb-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32"
          >
            <span class="mr-2 leading-none">Acciones</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon
                  icon="SaveIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>Exportar CSV</span>
              </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="foundPlants"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    >
    </ag-grid-vue>
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </vx-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import {
  FRANCHISES,
  PLANTS,
  SEARCH_PLANTS,
  DELEGATIONS,
  NO_ASGINED_TO_PLANT_DELIVERYMEN,
} from '@/graphql/queries';
import CellRendererPlantStatus from './CellRendererPlantStatus';
import {
  TOGGLE_ACTIVE_PLANT,
  RESET_PLANT_DELEGATIONS,
  NOTIFY_PLANT_DELEGATIONS,
  SYNC_DELEGATIONS,
  LAUNCH_PLANT_DELEGATIONS,
} from '@/graphql/mutations';
export default {
  components: {
    AgGridVue,
    CellRendererPlantStatus,
  },
  data() {
    return {
      fileName: 'plants.xlsx',
      activo: true,
      //Grid
      searchQuery: '',
      franchises: [],
      foundPlants: [],
      searchPanel: {},
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'Activo',
          width: 100,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          cellRendererParams: { checkbox: true },
          cellRendererFramework: 'CellRendererPlantStatus',
        },
        {
          headerName: 'Planta',
          field: 'name',
          width: 175,
          filter: true,
        },
        {
          headerName: 'Franquicia',
          field: 'franchise.name',
          width: 175,
          filter: true,
        },
        {
          headerName: 'Lunes',
          field: 'availability.monday',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Martes',
          field: 'availability.tuesday',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Miércoles',
          field: 'availability.wednesday',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Jueves',
          field: 'availability.thursday',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Viernes',
          field: 'availability.friday',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Sábado',
          field: 'availability.saturday',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Domingo',
          field: 'availability.sunday',
          filter: true,
          width: 100,
        },
      ],
    };
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.maxPageNumbers = 5;
        // this.gridOptions.columnApi.setColumnPinned('email', null);
      }
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    syncDelegations() {
      const ids = this.gridApi.getSelectedRows().map((e) => {
        return e.id;
      });
      console.log(
        '🚀 ~ file: TablaAdminPlantas.vue ~ line 280 ~ ids ~ ids',
        ids
      );
      this.$apollo.mutate({
        mutation: SYNC_DELEGATIONS,
        variables: {
          ids,
        },
      });
    },
    exportAllPlants() {
      this.$http
        .get('api/plants/export', { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    },
    async resetDelegations() {
      const ids = this.gridApi.getSelectedRows().map((e) => {
        return e.id;
      });
      await this.$apollo.mutate({
        mutation: RESET_PLANT_DELEGATIONS,
        variables: {
          ids,
        },
        update: () => {
          this.$apollo.query({
            query: NO_ASGINED_TO_PLANT_DELIVERYMEN,
            fetchPolicy: 'network-only',
          });
          try {
            this.$apollo.query({
              query: DELEGATIONS,
              fetchPolicy: 'network-only',
              variables: {
                first: 15,
                page: 1,
                environment: 'PLANNED',
              },
            });
          } catch {
            console.log();
          }
          try {
            this.$apollo.query({
              query: DELEGATIONS,
              fetchPolicy: 'network-only',
              variables: {
                first: 15,
                page: 1,
                environment: 'PRODUCTION',
              },
            });
          } catch {
            console.log();
          }
        },
      });
      this.$vs.notify({
        color: 'success',
        title: 'Delegaciones reiniciadas',
      });
      await this.getPlantSearch();
    },
    resetDelegationsDialog() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar acción destructiva',
        text: '¿Esta seguro de reestablecer todas las delegaciones de las plantas seleccionadas?',
        accept: () => {
          this.resetDelegations();
        },
        acceptText: 'Reestablecer',
        cancelText: 'Cancelar',
      });
    },
    sendNotificationDialog() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Confirmar envio de Mensajes de Texto',
        text: '¿Esta seguro de notificar via SMS a todos los postulados a plantas un recordatorio?',
        accept: () => {
          this.sendNotification();
        },
        acceptText: 'Notificar',
        cancelText: 'Cancelar',
      });
    },
    fusionDialog() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Acción destructiva',
        text: '¿Esta seguro de lanzar esta planeación de plantas a producción?',
        accept: () => {
          this.fusion();
        },
        acceptText: 'Fusionar',
        cancelText: 'Cancelar',
      });
    },
    async fusion() {
      const ids = this.gridApi.getSelectedRows().map((e) => {
        return e.id;
      });
      await this.$apollo.mutate({
        mutation: LAUNCH_PLANT_DELEGATIONS,
        variables: {
          ids,
        },
        update: () => {
          try {
            this.$apollo.query({
              query: DELEGATIONS,
              fetchPolicy: 'network-only',
              variables: {
                first: 15,
                page: 1,
                environment: 'PLANNED',
              },
            });
          } catch {
            console.log();
          }
          try {
            this.$apollo.query({
              query: DELEGATIONS,
              fetchPolicy: 'network-only',
              variables: {
                first: 15,
                page: 1,
                environment: 'PRODUCTION',
              },
            });
          } catch {
            console.log();
          }
        },
      });
      this.$vs.notify({
        color: 'success',
        title: 'Cambio de estado realizado',
      });
    },
    async sendNotification() {
      const ids = this.gridApi.getSelectedRows().map((e) => {
        return e.id;
      });
      await this.$apollo.mutate({
        mutation: NOTIFY_PLANT_DELEGATIONS,
        variables: {
          ids,
        },
      });
      this.$vs.notify({
        color: 'success',
        title: 'Cambio de estado realizado',
      });
    },
    changeStatusPlantDialog(desiredState) {
      this.$vs.dialog({
        type: 'confirm',
        color: `${desiredState ? 'success' : 'danger'}`,
        title: `Confirmar cambio de estado`,
        text: `¿Estás seguro de ${
          desiredState ? 'activar' : 'desactivar'
        } las plantas seleccionadas?`,
        accept: () => {
          this.changeStatusPlant(desiredState);
        },
        acceptText: `${desiredState ? 'Activar' : 'Desactivar'}`,
        cancelText: 'Cancelar',
      });
    },
    async changeStatusPlant(desiredState) {
      const ids = this.gridApi.getSelectedRows().map((e) => {
        return e.id;
      });
      await this.$apollo.mutate({
        mutation: TOGGLE_ACTIVE_PLANT,
        variables: {
          ids,
          active: desiredState,
        },
        update: () => {
          this.$apollo.query({
            query: NO_ASGINED_TO_PLANT_DELIVERYMEN,
            fetchPolicy: 'network-only',
          });
          try {
            this.$apollo.query({
              query: DELEGATIONS,
              fetchPolicy: 'network-only',
              variables: {
                first: 15,
                page: 1,
                environment: 'PLANNED',
              },
            });
          } catch {
            console.log();
          }
          try {
            this.$apollo.query({
              query: DELEGATIONS,
              fetchPolicy: 'network-only',
              variables: {
                first: 15,
                page: 1,
                environment: 'PRODUCTION',
              },
            });
          } catch {
            console.log();
          }
        },
      });
      this.$vs.notify({
        color: 'success',
        title: 'Cambio de estado realizado',
      });
      await this.getPlantSearch();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    delegarSocio() {},
    async getPlantSearch() {
      this.gridOptions.loadingCellRenderer = true;
      const { data } = await this.$apollo.query({
        query: SEARCH_PLANTS,
        variables: {
          franchise_id: this.searchPanel.franchise_id,
        },
      });
      this.foundPlants = data.plants;
      this.gridOptions.loadingCellRenderer = false;
    },
    async getFranchises() {
      const { data } = await this.$apollo.query({
        query: FRANCHISES,
      });
      this.franchises = data.franchises;
    },
    async getPlants() {
      const { data } = await this.$apollo.query({
        query: PLANTS,
      });
      this.franchises = data.franchises;
    },
  },
  updated() {
    if (this.$store.state.windowWidth > 768) {
      this.gridApi.sizeColumnsToFit();
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridOptions.context = {
      componentParent: this,
    };

    this.getFranchises();
    this.getPlantSearch();
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_variables.scss';

.tabla-adminPlantas {
  &__prompt {
    .dialog-title {
      color: $nepal !important;
      font-weight: $font-weight-bold;
    }
  }
}
</style>

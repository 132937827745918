<template>
  <TablaAdminPlantas></TablaAdminPlantas>
</template>

<script>
import TablaAdminPlantas from '@/components/plantas/admin/TablaAdminPlantas.vue';
export default {
  components: {
    TablaAdminPlantas,
  },
};
</script>

<style></style>
